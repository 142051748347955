import _ from "lodash";
import { DELETE_PERMISSION, MOVE_PERMISSION, MAINTAIN_PERMISSION, EDITPERCENTAPPLIED_PERMISSION, EDITPERCENTOBSERVED_PERMISSION, GROWERS_SAMPLEPROCESSING, GROWERS_SAMPLEPROCESSING_OBSERVATION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-intake-samples"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _vm.entity ? _c("div", {
    staticClass: "header px-5 pt-3 pb-4 mb-5 d-flex justify-content-between align-items-center"
  }, [_c("div", {
    staticClass: "d-flex align-items-center flex-wrap"
  }, [_c("a-icon", {
    staticClass: "arrow-left pr-3",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": function($event) {
        return _vm.onBack();
      }
    }
  }), _c("span", {
    staticClass: "pr-3 header-title mb-0"
  }, [_vm._v(" Sample - " + _vm._s(_vm.entity.sampleNumber) + " ")])], 1)]) : _vm._e(), _vm.entity ? _c("div", {
    staticClass: "body mx-5"
  }, [_c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-row", {
          attrs: {
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "lg": 24,
            "xl": 8
          }
        }, [_c("a-card", {
          style: {
            height: "100%"
          }
        }, [_c("div", {
          staticClass: "d-flex flex-column",
          style: {
            height: "100%"
          }
        }, [_c("h2", {
          staticClass: "intake-title mb-3"
        }, [_vm._v(" Sample - " + _vm._s(_vm.entity.sampleNumber) + " ")]), _c("switch-input", {
          key: "lastSample",
          attrs: {
            "disabled": !_vm.isCreateForm,
            "label": "Last sample",
            "label-horizontal": "right",
            "value": _vm.entity.lastSample
          },
          on: {
            "change": function($event) {
              return _vm.formChange(_vm.entity.lastSample, "lastSample");
            }
          }
        }), _vm.entity ? _c("div", {
          staticClass: "mt-auto"
        }, [_vm._v(" Last modified by " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY HH:mm")) + " ")]) : _vm._e()], 1)])], 1), _c("a-col", {
          staticClass: "kh-edit-sample-info",
          attrs: {
            "lg": 24,
            "xl": 16
          }
        }, [_vm.entity ? _c("a-card", [_c("a-row", {
          attrs: {
            "gutter": [24, 24]
          }
        }, [_c("a-col", {
          attrs: {
            "lg": 24,
            "xl": 8
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Intake",
            "disabled": true,
            "value": "Intake - " + _vm.entity.intakeNumber,
            "span": 12
          }
        })], 1), _c("a-col", {
          attrs: {
            "lg": 24,
            "xl": 8
          }
        }, [_c("text-input", {
          key: "crop",
          attrs: {
            "label": "Crop",
            "disabled": true,
            "value": _vm.entity.cropCode + " " + _vm.entity.cropName,
            "span": 12
          }
        })], 1), _c("a-col", {
          attrs: {
            "lg": 24,
            "xl": 8
          }
        }, [_c("select-input", {
          key: "operatorId",
          attrs: {
            "label": "Operator",
            "reference": "grower.common.operators",
            "source": "id",
            "source-label": _vm.genOperatorsLabel,
            "value": _vm.entity.operatorId,
            "disabled": _vm.cacheEntity.grossWeight !== 0
          },
          on: {
            "change": function(e) {
              return _vm.formChange(e, "operatorId");
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "lg": 24,
            "xl": 8
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Sample Type",
            "disabled": true,
            "value": _vm.entity.sampleTypeCode + " - " + _vm.entity.sampleType,
            "span": 12
          }
        })], 1), _c("a-col", {
          attrs: {
            "lg": 24,
            "xl": 8
          }
        }, [_c("text-input", {
          key: "grossWeight",
          attrs: {
            "form-item": "",
            "label": "Gross Weight",
            "placeholder": "Gross Weight",
            "value": _vm.entity.grossWeight,
            "rules": "number|positive",
            "span": 24,
            "disabled": _vm.cacheEntity.grossWeight !== 0
          },
          on: {
            "change": function(e) {
              return _vm.formChange(e, "grossWeight");
            }
          }
        })], 1)], 1)], 1) : _vm._e()], 1)], 1), _vm.cacheEntity.grossWeight === 0 ? _c("div", {
          staticClass: "ant-modal-footer p-4"
        }, [_c("button", {
          staticClass: "ant-btn",
          attrs: {
            "type": "button",
            "disabled": !_vm.isChangeForm
          },
          on: {
            "click": _vm.cancelForm
          }
        }, [_c("span", [_vm._v("Cancel")])]), _vm.observationHasMaintainPermission ? _c("button", {
          staticClass: "ant-btn ant-btn-primary",
          attrs: {
            "type": "button",
            "disabled": _vm.entity.grossWeight === 0
          },
          on: {
            "click": function($event) {
              return handleSubmit(_vm.submitForm);
            }
          }
        }, [_c("span", [_vm._v("Save")])]) : _vm._e()]) : _vm._e()];
      }
    }], null, false, 3340785211)
  }), _c("a-card", {
    staticClass: "list-samples mt-4"
  }, [_c("div", {
    staticClass: "mb-3 d-flex align-items-center justify-content-between"
  }, [_c("h6", {
    staticClass: "h6"
  }, [_vm._v("Observations (" + _vm._s(_vm.observations.length) + ")")]), _vm.isCreateForm || _vm.cacheEntity.grossWeight === 0 ? _c("select-input", {
    staticClass: "active-sample-filter",
    attrs: {
      "source": "value",
      "source-label": "text",
      "data-source": _vm.activeSampleFilter,
      "value": _vm.editWeight,
      "disabled": _vm.editingKey !== ""
    },
    on: {
      "change": _vm.changeWeightEdit
    }
  }) : _vm._e()], 1), _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref2) {
        var handleSubmit = _ref2.handleSubmit, invalid = _ref2.invalid;
        return [_c("a-table", {
          attrs: {
            "columns": _vm.columns,
            "data-source": _vm.observations,
            "row-key": "id",
            "loading": _vm.entity === {},
            "scroll": {
              x: _vm.scrollX
            },
            "pagination": false,
            "bordered": ""
          },
          scopedSlots: _vm._u([_vm._l(["id", "type", "code", "description", "weightObserved", "weightRemaining", "percentObserved", "percentApplied"], function(col) {
            return {
              key: col,
              fn: function(text, record, index2) {
                return [_c("div", {
                  key: col
                }, [_vm.observationHasMaintainPermission && record.editable && (record.manualEntry ? col == "weightObserved" : col == _vm.editWeight || !_vm.isCreateForm && col == "percentApplied" && record.type !== "A" && !record.cropObservedTolerance && !record.observedTolerance || col == "percentApplied" && record.type === "A") ? _c("text-input", {
                  staticStyle: {
                    "margin": "-5px 0"
                  },
                  attrs: {
                    "form-item": "",
                    "value": text,
                    "label": col,
                    "show-label": false,
                    "rules": {
                      required: true,
                      regex: _vm.decimalRegex,
                      max_value: _vm.observations[index2 - 1] ? _vm.observations[index2 - 1].weightRemaining : _vm.entity.grossWeight
                    }
                  },
                  on: {
                    "change": function($event) {
                      return _vm.handleChange($event, record.id, col);
                    }
                  }
                }) : [_vm._v(_vm._s(text))]], 2)];
              }
            };
          }), {
            key: "action",
            fn: function(text, record) {
              return [_c("div", {
                staticClass: "editable-row-action"
              }, [_vm.observationHasMaintainPermission && record.editable ? [_c("a-tooltip", {
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function() {
                    return [_vm._v("Save")];
                  },
                  proxy: true
                }], null, true)
              }, [_c("a-button", {
                attrs: {
                  "disabled": invalid,
                  "icon": "check",
                  "size": "small",
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.saveItem);
                  }
                }
              })], 1), _c("a-tooltip", {
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function() {
                    return [_vm._v("Cancel")];
                  },
                  proxy: true
                }], null, true)
              }, [_c("a-button", {
                attrs: {
                  "icon": "close",
                  "size": "small",
                  "type": "button"
                },
                on: {
                  "click": function($event) {
                    return _vm.cancelItem(record.id);
                  }
                }
              })], 1)] : [_vm.observationHasMaintainPermission ? _c("a-tooltip", {
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function() {
                    return [_vm._v("Edit")];
                  },
                  proxy: true
                }], null, true)
              }, [_c("a-button", {
                attrs: {
                  "disabled": _vm.editingKey !== "",
                  "icon": "edit",
                  "size": "small",
                  "type": "link"
                },
                on: {
                  "click": function($event) {
                    return _vm.editItem(record.id);
                  }
                }
              })], 1) : _vm._e()]], 2)];
            }
          }], null, true)
        })];
      }
    }], null, false, 2529743068)
  })], 1), _vm.cacheEntity.grossWeight !== 0 ? _c("div", {
    staticClass: "ant-modal-footer p-4"
  }, [_vm.sampleHasDeletePermission ? _c("a-button", {
    attrs: {
      "ghost": "",
      "type": _vm.entity.isInactive ? "primary" : "danger"
    },
    on: {
      "click": function($event) {
        return _vm.submitForm(true);
      }
    }
  }, [_vm.entity.isInactive ? _c("span", [_vm._v("Activate")]) : _c("span", [_vm._v("Deactivate")])]) : _vm._e(), _vm.sampleHasMovePermission ? _c("a-button", {
    attrs: {
      "ghost": "",
      "type": "primary"
    },
    on: {
      "click": _vm.moveSample
    }
  }, [_c("span", [_vm._v("Move Sample")])]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.sampleHasMovePermission ? _c("a-modal", {
    attrs: {
      "title": "Move Sample",
      "centered": "",
      "ok-button-props": {
        props: {
          disabled: !_vm.moveIntakeId
        }
      }
    },
    on: {
      "ok": _vm.submitModal
    },
    model: {
      value: _vm.visibleModal,
      callback: function($$v) {
        _vm.visibleModal = $$v;
      },
      expression: "visibleModal"
    }
  }, [_vm.commonIntake.length > 0 ? _c("a-row", {
    attrs: {
      "gutter": [24, 24]
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Current Intake",
      "disabled": true,
      "value": "Intake - " + _vm.entity.intakeNumber,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("select-input", {
    attrs: {
      "label": "Select Intake to Move",
      "data-source": _vm.commonIntake,
      "source": "id",
      "source-label": _vm.genIntakeLabel
    },
    on: {
      "change": _vm.onIntakeChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    key: "crop",
    attrs: {
      "label": "Sample Number",
      "disabled": true,
      "value": _vm.entity.sampleNumber,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Sample Type",
      "disabled": true,
      "value": _vm.entity.sampleTypeCode + " - " + _vm.entity.sampleType,
      "span": 12
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
};
var staticRenderFns$1 = [];
var EditSampleObservations_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditSampleObservations",
  inject: ["apiUrl", "resourceName", "redirectRoute", "crud", "canProps"],
  data() {
    const [samplePermissions, observationPermissions] = this.canProps;
    return {
      intakeId: this.$route.params.id,
      sampleId: this.$route.query.sampleId,
      formType: this.$route.query.type ? this.$route.query.type : "edit",
      customUrl: `${this.apiUrl}/grower/intake-samples`,
      entity: {},
      isCreateForm: false,
      columns: [],
      observations: [],
      observationsCache: [],
      scrollX: 0,
      editingKey: "",
      itemsMenu: [
        {
          key: "sampleprocessing",
          title: "Sample Processing",
          path: ""
        },
        {
          key: "intake-samples",
          title: "Intake list",
          path: "/watties-grower/intake-samples"
        }
      ],
      decimalRegex: /^\d+(\.\d{1,2})?$/,
      isChangeForm: false,
      cacheEntity: {},
      editWeight: "weightObserved",
      isEdit: false,
      activeSampleFilter: [
        {
          value: "weightObserved",
          text: "Weight Observed"
        },
        {
          value: "weightRemaining",
          text: "Weight Remaining"
        }
      ],
      visibleModal: false,
      commonIntake: [],
      moveIntakeId: null,
      samplePermissions,
      observationPermissions
    };
  },
  computed: {
    sampleHasDeletePermission() {
      return this.samplePermissions.can(DELETE_PERMISSION);
    },
    sampleHasMovePermission() {
      return this.samplePermissions.can(MOVE_PERMISSION);
    },
    observationHasMaintainPermission() {
      return this.observationPermissions.can(MAINTAIN_PERMISSION);
    },
    observationHasEditPercentAppliedPermission() {
      return this.observationPermissions.can(EDITPERCENTAPPLIED_PERMISSION);
    },
    observationHasEditPercentObservedPermission() {
      return this.observationPermissions.can(EDITPERCENTOBSERVED_PERMISSION);
    }
  },
  async mounted() {
    this.isTabletScreen = window.matchMedia("(max-width: 1024px)").matches ? true : false;
    await this.crud.fetchEntity().then((res) => {
      this.entity = res.data;
    });
    this.cacheEntity = Object.assign({}, this.entity);
    this.isCreateForm = this.formType === "create";
    this.observations = (this.cacheEntity.grossWeight !== 0 ? this.entity.observations : []).map((obs) => {
      const {
        deductObservedTolerance,
        deductObservedLimit,
        deductAppliedPercentage,
        gradeObservedTolerance,
        gradeObservedLimit,
        gradeAppliedPercentage
      } = this.entity;
      let cropObservedTolerance = false;
      let cropObservedToleranceLimit = 0;
      let cropAppliedDeduction = 0;
      switch (obs.type) {
        case "D":
          cropObservedTolerance = deductObservedTolerance;
          cropObservedToleranceLimit = deductObservedLimit;
          cropAppliedDeduction = deductAppliedPercentage;
          break;
        case "G":
          cropObservedTolerance = gradeObservedTolerance;
          cropObservedToleranceLimit = gradeObservedLimit;
          cropAppliedDeduction = gradeAppliedPercentage;
          break;
      }
      return {
        ...obs,
        cropObservedTolerance,
        cropObservedToleranceLimit,
        cropAppliedDeduction
      };
    });
    this.observationsCache = this.cacheEntity.grossWeight !== 0 ? _.cloneDeep(this.observations) : [];
    this.observations = this.updateWeightObservations();
    this.createColumns();
    this.itemsMenu = [
      ...this.itemsMenu,
      {
        key: "intake-items",
        title: "Intake - " + this.entity.intakeNumber,
        path: "/watties-grower/intake-samples/" + this.intakeId
      },
      {
        key: `${this.entity && this.entity.id}`,
        title: `Sample - ${this.entity && this.entity.sampleNumber}`,
        path: `/watties-grower/intake-samples/${this.intakeId}/samples/?sampleId=${this.entity && this.entity.id}`
      }
    ];
  },
  methods: {
    genOperatorsLabel(option) {
      return `${option.id} - ${option.name}`;
    },
    createColumns() {
      this.columns = [
        {
          title: "Observation Type",
          dataIndex: "type",
          width: 140,
          fixed: this.isTabletScreen ? "left" : false,
          scopedSlots: { customRender: "type" }
        },
        {
          title: "Observation Code",
          dataIndex: "code",
          width: 140,
          scopedSlots: { customRender: "code" }
        },
        {
          title: "Observation Description",
          dataIndex: "description",
          scopedSlots: { customRender: "description" }
        },
        {
          title: "Weight Observed",
          dataIndex: "weightObserved",
          width: 130,
          scopedSlots: { customRender: "weightObserved" }
        }
      ];
      this.columns = !this.isCreateForm ? [
        ...this.columns,
        {
          title: "Percent Observed",
          dataIndex: "percentObserved",
          width: 180,
          scopedSlots: { customRender: "percentObserved" }
        },
        {
          title: "Percent Applied",
          dataIndex: "percentApplied",
          width: 180,
          scopedSlots: { customRender: "percentApplied" }
        },
        {
          title: "Action",
          dataIndex: "action",
          width: 78,
          fixed: this.isTabletScreen ? "right" : false,
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ] : [
        ...this.columns,
        {
          title: "Weight Remaining",
          dataIndex: "weightRemaining",
          width: 180,
          scopedSlots: { customRender: "weightRemaining" }
        },
        {
          title: "Percent Observed",
          dataIndex: "percentObserved",
          width: 180,
          scopedSlots: { customRender: "percentObserved" }
        },
        {
          title: "Action",
          dataIndex: "action",
          width: 78,
          fixed: this.isTabletScreen ? "right" : false,
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ];
    },
    formChange(value, key) {
      value = key === "lastSample" ? !value : value;
      this.entity[key] = value;
      this.isChangeForm = !this._.isEqual(this.entity, this.cacheEntity);
    },
    submitForm(toggleActive = false) {
      if (toggleActive)
        this.entity.isInactive = !this.entity.isInactive;
      this.storeValue(this.entity, "intakeId,lastSample,operatorId,grossWeight,isInactive");
      this.crud.submitEntity("replace", `${this.customUrl}/${this.intakeId}/samples/${this.sampleId}/`).then((response) => {
        delete this.entity.intakeId;
        Object.assign(this.entity, response.data);
        Object.assign(this.cacheEntity, response.data);
        this.observations = this.entity.observations;
        this.observationsCache = _.cloneDeep(this.observations);
        this.observations = this.updateWeightObservations();
        this.isEdit = false;
        this.visibleModal = false;
        this.isChangeForm = false;
      }).catch(() => {
        delete this.entity.intakeId;
        this.cancelForm();
        this.isChangeForm = false;
      });
    },
    cancelForm() {
      Object.assign(this.entity, this.cacheEntity);
      this.isChangeForm = false;
    },
    storeValue(value, resourceKey) {
      if (!resourceKey.includes(",")) {
        this.crud.updateEntity("editForm", { resourceKey, value });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => this.crud.updateEntity("editForm", {
        resourceKey: key,
        value: key !== "intakeId" ? value[key] : value["intakeId"] ? value["intakeId"] : this.intakeId
      }));
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    changeWeightEdit(value) {
      this.editWeight = value;
    },
    handleChange: _.debounce(function(value, id, column) {
      const newObservations = [...this.observations];
      const target = newObservations.filter((item) => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.observations = newObservations;
        this.isEdit = true;
      }
      this.observations = this.updateWeightObservations(target.id);
    }, 400),
    editItem(id) {
      const newObservations = [...this.observations];
      const target = newObservations.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.observations = newObservations;
      }
    },
    async saveItem() {
      const newObservations = [...this.observations];
      const newObservationsCache = [...this.observationsCache];
      const targets = newObservations.filter((observation, index2) => parseFloat(observation.percentObserved).toFixed(1) != parseFloat(newObservationsCache[index2].percentObserved).toFixed(1));
      try {
        for (const target of targets) {
          this.storeValue(target, "weightObserved,percentObserved,percentApplied,lastChangeReason");
          await this.crud.submitEntity("replace", `${this.apiUrl}/grower/intake-observations/${target.id}`);
        }
        this.observations = this.observations.map((observation) => ({
          ...observation,
          editable: false
        }));
        this.isEdit = false;
        this.editingKey = "";
        this.observationsCache = _.cloneDeep(this.observations);
      } catch (error) {
        this.editingKey = "";
        this.observations = _.cloneDeep(this.observationsCache);
      }
    },
    cancelItem(id) {
      const newObservations = [...this.observations];
      const target = newObservations.filter((item) => id === item.id)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(target, this.observationsCache.filter((item) => id === item.id)[0]);
        delete target.editable;
        this.observations = newObservations;
        this.observations = this.updateWeightObservations();
      }
    },
    calculateObs(preElement, element, type = "weightObserved", updateType = "weightRemaining", baseWeight) {
      if (element.manualEntry) {
        element.weightRemaining = parseFloat(0).toFixed(2);
      } else {
        let neGrossWeight = (preElement ? preElement.weightRemaining : baseWeight) - element[type];
        let calc = parseFloat(neGrossWeight).toFixed(2);
        element[updateType] = calc > 0 ? calc : 0;
      }
      return element;
    },
    updateWeightObservations(id = -1) {
      let observations = this.observations;
      let baseWeight = this.entity.grossWeight;
      let totalPercentObserved = observations.reduce((total, obs) => total + +obs.percentObserved, 0);
      observations.forEach((item, index2) => {
        let prevItem = observations[index2 - 1];
        if (prevItem && prevItem.weightRemaining === 0) {
          for (let i = index2 - 2; i >= 0; i -= 1) {
            if (observations[i].weightRemaining !== 0) {
              prevItem = observations[i];
              break;
            }
          }
        }
        if (prevItem && prevItem.type != item.type) {
          baseWeight = prevItem.weightRemaining;
        }
        if (id !== -1 && this.editWeight === "weightRemaining" && item.id === id) {
          item = this.calculateObs(prevItem, item, "weightRemaining", "weightObserved", baseWeight);
        } else {
          item = this.calculateObs(this.observations[index2 - 1], item, "weightObserved", "weightRemaining", baseWeight);
        }
        item.percentObserved = item.manualEntry ? parseFloat(0).toFixed(1) : baseWeight !== 0 ? parseFloat(item.weightObserved / baseWeight * 100).toFixed(1) : 0;
        if (item.observedTolerance) {
          item.percentApplied = item.percentObserved > item.observedToleranceLimit ? item.percentObserved : item.appliedDeduction || 0;
        } else if (item.cropObservedTolerance) {
          item.percentApplied = totalPercentObserved > item.cropObservedToleranceLimit ? item.percentObserved : item.cropAppliedDeduction || 0;
        } else {
          item.percentApplied = item.percentObserved;
        }
      });
      return observations;
    },
    onBack() {
      this.$router.push(`/watties-grower/intake-samples/${this.$route.params.id}`);
    },
    moveSample() {
      this.axios.get(`${this.apiUrl}/grower/common/intakes?Status=S&Status=E`).then((res) => {
        this.commonIntake = res.data;
      }, (err) => console.log(err)).finally(() => {
        this.visibleModal = true;
      });
    },
    submitModal() {
      this.entity.intakeId = this.moveIntakeId;
      this.submitForm();
    },
    genIntakeLabel(option) {
      return `Intake - ${option.intakeNumber}`;
    },
    onIntakeChange(e) {
      this.moveIntakeId = e;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "2c795dba", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditSampleObservations = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return this.$route.query.sampleId ? _c("div", {
    staticClass: "edit-sample"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.operators",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.intakes",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": _vm.name,
      "api-url": _vm.apiUrl,
      "edit-route-id-name": "sampleId",
      "redirect-route": _vm.redirectRoute,
      "page": _vm.pages
    }
  }, [_c("edit-sample-observations")], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditSampleObservations
  },
  data() {
    let intakeId = this.$route.params.id;
    let sampleId = this.$route.query.sampleId;
    return {
      apiUrl,
      EditSampleObservations,
      name: `grower.intake-samples.${intakeId}.samples.${sampleId}.observations`,
      redirectRoute: `/watties-grower/intake-samples/${intakeId}`,
      pages: [GROWERS_SAMPLEPROCESSING, GROWERS_SAMPLEPROCESSING_OBSERVATION]
    };
  },
  created() {
    if (!this.$route.query.sampleId)
      this.$router.push(`/watties-grower/intake-samples/${this.$route.params.id}`);
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
